import React from 'react';
import Image from 'components/Image';
import { Tooltip } from 'antd';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  name?: '2048' | 'E2048' | 'BUSD';
}

function TokenLogo({
  src,
  alt,
  width,
  height,
  className,
  name,
  id,
  ...rest
}: Props) {
  return (
    <Tooltip
      placement="bottom"
      title={name == 'BUSD' ? 'BUSD' : name === '2048' ? 'ETH' : 'Energy Token'}
    >
      <Image
        id={id}
        className={className}
        style={{ width, height }}
        src={`/image/token/${name}.svg`}
        alt={name == 'BUSD' ? 'BUSD' : name === '2048' ? 'ETH' : 'Energy Token'}
        {...rest}
      />
    </Tooltip>
  );
}

export default TokenLogo;
